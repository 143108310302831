import './Country.scss'
import React, { useState, useEffect } from 'react'
import { getCountryName } from '../../utils/helper'
import config from '../../config';

const Country = ({ changeStep }) => {
  const [countryName, setCountryName] = useState('')

  useEffect(() => {
    fetch(`https://ipinfo.io?token=${config.ipinfo}`)
      .then(res => res.json())
      .then(data => setCountryName(getCountryName(data.country)))
  }, [])
  
  return (
    <div className='Text__item--big'>
      {countryName}
    </div>
  )
}

export default Country