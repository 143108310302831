import React, { useState, useEffect } from 'react'
import './Landing.scss'
import Footer from '../../components/Footer/Footer'
import Frame from '../../components/Frame/Frame'

const Landing = () => {
    const [productParam, setProductParam] = useState(false)
    const [stepsParam, setStepsParam] = useState(false)
    // const [textParam, setTextParam] = useState(false)

    const [background, setBackground] = useState(false)
    const [quizstyle, setQuizstyle] = useState(false)
    const [product, setProduct] = useState(false)
    const [steps, setSteps] = useState(false)
    const [stepsCount, setStepsCount] = useState(false)

    const [step, setStep] = useState(1)
    const [showTitle, setShowTitle] = useState(true)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const background = params.get('background')
        const quizstyle = params.get('quizstyle')
        const product = params.get('product')
        const steps = params.get('steps')
        // const text = params.get('text')

        setBackground(background || '01')
        setQuizstyle(quizstyle || '01')
        setProductParam(product || '01')
        setStepsParam(steps || '01')
        // setTextParam(text || '01')
    }, [])

    useEffect(() => {
        if (productParam) fetch(`/content/products/${productParam}/product.json`)
            .then(res => res.json())
            .then(data => setProduct(data.url))
    }, [productParam])

    useEffect(() => {
        if (stepsParam) fetch(`/content/steps/${stepsParam}/steps.json`)
            .then(res => res.json())
            .then(data => {
                setSteps(data.steps)
                setStepsCount(data.steps && data.steps.length)
                setShowTitle(data.title)
            })
    }, [stepsParam])

    useEffect(() => {
        console.log('steps == ', steps)
    }, [steps])

    useEffect(() => {
        console.log('step == ', step)
    }, [step])

    const getStyle = () => {
        return {
            background: `url(/content/backgrounds/${background}/bkg.jpg) top center / cover`,
        }
    }

    const redirect = () => {
        return step === stepsCount ? product : false
    }

    const changeStep = (number) => {
        if (showTitle) setShowTitle(false)
        setStep(step => step + number);
    }

    return (
        <div className='Landing' style={getStyle()}>
            <Frame stepsCount={stepsCount} step={step} stepData={steps[step - 1]} title={showTitle} redirect={redirect()} quizstyle={quizstyle} changeStep={changeStep} />
            <Footer />
        </div>
    )
}

export default Landing