import './Footer.scss'

const Footer = () => {
  return (
    <div className='Footer'>
        <a className='Footer__link' href='https://plarium.com/en/terms-of-use/' target='_blanc'>Terms of use</a>
        <a className='Footer__link' href='https://company.plarium.com/en/contacts/' target='_blanc'>Contacts</a>
        <a className='Footer__link' href='https://plarium.com/en/privacy-and-cookie-policy/' target='_blanc'>Privacy and cookie policy</a>
    </div>
  )
}

export default Footer