import './Text.scss'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { getBrowser } from '../../utils/helper'
import Loading from '../../components/Loading/Loading'
import Country from '../../components/Country/Country'

const Text = ({ rows, changeStep }) => {
  const renderItem = (item) => {
    if (item === 'show_loading') {
      return <Loading changeStep={changeStep} />
    } else if (item === 'check_country') {
      return <Country />
    } else if (item === 'check_browser') {
      const browser = getBrowser()
      return <div className='browser'>
        <img className='browser__img' src={`/img/browsers/${browser.value}.png`} alt='Your browser' />
        <span className='Text__item--big'>{browser.name}</span>
      </div>
    } else {
      return <span>{item}</span>
    }
  }

  const renderRows = () => {
    if (Array.isArray(rows))
      return rows.map((item, index) =>
        <TransitionGroup key={item} className='Text__item'>
          <CSSTransition
            appear={true}
            key={index}
            exit={false}
            classNames="notifyTransition"
            timeout={{ appear: 500, enter: 500, exit: 0 }}
          >
            <div>
              {renderItem(item)}
            </div>
          </CSSTransition>
        </TransitionGroup>)
    return null
  }

  return (
    <div className='Text'>
      {renderRows()}
    </div>
  )
}

export default Text